<template>
    <div class="training">
		<div class="headerTab">
			<span class="tabspan" :class="tabId === tab.id ? 'tabactive' : ''" @click="changeTab(tab.id)" v-for="(tab, index) in tabTitle" :key="index">{{tab.title}}</span>
		</div>
        <div class="examblock-filter">
            <span>{{filterText}}：</span>
            <span
                @click="changstatusTab(item.id)"
                :class="activeTab === item.id ? 'activeSpan' : 'unactiveSpan'"
                v-for="(item, index) in filterList"
                :key="index"
            >{{item.name}}</span>
        </div>
        <div>
            <examBlock ref="examlist" v-if="tabId === 1"></examBlock>
        </div>
        <div v-if="tabId === 0" class="infinite-list-wrapper" style="overflow:auto">
            <ul class="training-list">
                <li v-for="el in trainList" :key="el.id">
                    <img :src="el.iconUrl" alt />
                    <div class="detail">
                        <p class="info-title">{{el.name}}</p>
                        <p
                            class="date"
                        >{{el.trainStartTime | dataFormat}}至{{el.trainEndTime| dataFormat}}</p>
                        <span class="info">
                            训练营简介：{{el.description|ellipsis}}
                            <a
                                @click="info=true;currentTrain = el"
                            >查看简介</a>
                        </span>
                        <div class="process">
                            <span v-if="el.studyProcess">{{'已完成'+el.studyProcess}}%</span>
                            <span
                                class="continue"
                                @click="toCatalog(el.id)"
                            >{{el.studyProcess?'继续学习':'开始学习'}}</span>
                        </div>
                    </div>
                </li>
                <article v-if="!trainList.length" class="no-course">
                    <img class="notraining" src="../../../assets/images/common/nodata.png" alt />
                    <p>{{noCon}}</p>
                </article>
            </ul>
            <p class="loadingmore" v-if="loading">加载中...</p>
            <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
        </div>
        <!-- <paging :paging="paging" :cardPage="cardPage" v-if="trainList.length && tabId === 0" /> -->
        <dialogInfo :info="info" :currentCourse="currentTrain" @infoShow="infoShow"></dialogInfo>
    </div>
</template>

<script>
import $ from 'jquery';
import dialogInfo from "./dialogInfo.vue";
import examBlock from "./examBlock.vue";
// import paging from "../../../layout/paging";
export default {
    name: "training",
    components: {
        dialogInfo,
        // paging,
        examBlock
    },
    data() {
        return {
            loading: false,
            filterText: '训练营筛选',
            activeTab: -1,
            filterList: [
                { id: -1, name: "全部" },
                { id: 1, name: "未开始" },
                { id: 2, name: "进行中" },
                { id: 3, name: "已结束" }
            ],
            trainList: [],
            info: false,
			currentTrain: {},
			tabId: 0,
			tabTitle: [{
				id: 0,
				title: '我的训练营',
			}, {
				id: 1,
				title: '我的考试'
			}],
            // title: "我的课程",
            noCon: "暂无训练营",
            paging: {
                params: {
                    pageNum: 1,
                    pageSize: 9
                },
                total: 0
            },
            trainStatus: null,
            cardPage: "card"
        };
    },
    filters: {
        dataFormat(msg) {
            const date = new Date(msg);
            const m = date.getMonth() + 1;
            return date.getFullYear() + "." + m + "." + date.getDate();
        },
        ellipsis(msg) {
            if (msg.length > 31) {
                return msg.slice(0, 30) + "...";
            }
            return msg;
        }
    },
    computed: {
        noMore () {
            return this.trainList.length === this.paging.total && this.paging.total !== 0
        },
    },
    beforeRouteEnter (to, from, next) {
        // ...
        next( vm => {
            if(from.query.type === 'exam'){
                vm.changeTab(1);  
            }
        })
    },
    created() {
        let conArr = JSON.parse(localStorage.getItem("configurationArr"));
        let name = "";
        conArr.forEach(item => {
            if (item.key == "custom_train_label") {
                name = item.value;
            }
        });
        conArr.forEach(item => {
            if (item.key == "is_show_custom_title" && item.value == "true") {
                this.title = name;
                this.noCon = "暂无课程";
            }
        });
    },
    mounted() {
        if (this.$route.query.type === 'exam') {
            this.changeTab(1);
        } else {
            this.changeTab(0);
        }
        $(window).scroll(() => {
            //判断是否滑动到页面底部
            if (
                Math.round($(window).scrollTop()) ===
                $(document).height() - $(window).height()
            ) {
                // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
                console.log('页面底部')
                
                setTimeout(this.reachBottom(), 1000)
            }
        });
    },
    methods: {
        reachBottom() {
            if (this.paging.total > this.paging.params.pageNum * this.paging.params.pageSize) {
                this.paging.params.pageNum = this.paging.params.pageNum + 1;
                this.loading = true;
                this.getData("reachBottom");
            } else {
                this.loading = false;
            }
        },
        changstatusTab(id) {
            this.activeTab = id;
            if(this.tabId === 1){
                this.$nextTick(() => {
                    this.$refs.examlist.examPaging.params.pageNum = 1;
                    this.$refs.examlist.examPaging.params.pageSize = 10;
                    
                    this.$refs.examlist.examStatus = id === -1 ? null : id;
                    this.$refs.examlist.getData();
                })
                
            } else {
                this.paging.params = {
                    pageNum: 1,
                    pageSize: 9
                }
                this.trainStatus = id === -1 ? null : id;
                this.getData();
            }
            
        },
		changeTab(id) {
            this.tabId = id;
            this.filterText = id === 0 ? '训练营筛选' : '考试筛选';
            id === 0 ? this.changstatusTab(-1) : this.changstatusTab(2);
            // if(this.tabId === 0) {
            //     this.getData();
            // }
        },
        getData() {
            this.$api.training
                .trainList({
                    params: {
                        pageNum: this.paging.params.pageNum,
                        pageSize: this.paging.params.pageSize,
                        trainStatus: this.trainStatus
                    }
                })
                .then(({ data: { list, total } }) => {
                    // this.trainList = list;
                    if(this.paging.params.pageNum>1){
                        this.trainList = this.trainList.concat(list);
                    } else {
                        this.trainList = list;
                    }
                    this.paging.total = total;
                    console.log(list, 'trainList')
                });
                
        },
        infoShow(val) {
            this.info = val;
        },
        toCatalog(id) {
            this.$router.push({
                path: "/course/catalog",
                query: {
                    id,
                    taskType:1,
                    dymode: this.$route.query.dymode
                }
            });
        }
    }
};
</script>

<style lang="stylus" scoped>
@import '../css/training.styl';
</style>
